import { myVm } from '@/main.js';

export default {
  async fetchUser(id) {
    if (!id) {
      throw 'Must include an ID in fetchUser call';
    }
    let data = undefined;
    try {
      data = await myVm.$store.dispatch('apiGetData', {
        url: '/users/' + id,
        commit: false,
        responseProperty: 'Items',
        errorAlert: 'ERROR: Could not retrieve user with ID ' + id,
      });
    } catch (err) {
      console.log(err);
      data = null;
    }
    return data;
  },
  async fetchPrjLayers(prjID) {
    if (!prjID) {
      throw 'Must include an ID in fetchPrjLayers call';
    }
    let data = undefined;
    try {
      data = await myVm.$store.dispatch('apiGetData', {
        url: '/projects/' + prjID + '/layers/',
        commit: false,
        responseProperty: 'Items',
        errorAlert: 'ERROR: Could not retrieve layers for project with ID ' + prjID,
      });
    } catch (err) {
      console.log(err);
      data = null;
    }
    return data;
  }
};  // end of export default block
