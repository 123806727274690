<template>
  <div class="geoeaturemap" :style="cssVars">
    <h1 class="page-header primary">
      Geofeature Map
      <span v-if="geofeatures" class="f5">({{ geofeatures.length }})</span>
    </h1>
    <div id="mapParentContainer">
      <div id="mapContainer" style="height: 500px">
        <i class="fa fa-home f3 gray" @click="centerMapToStartPosition" />
        <!-- <span class="f6 fc">center: {{currentCenter}}</span> -->
        <span v-if="cursorLocation" class="f6 fr"
          >cursor: {{ cursorLocation }}</span
        >
        <l-map
          ref="myMap"
          style="height: 80%"
          :zoom.sync="currentZoom"
          :center.sync="currentCenter"
          :options="mapOptions"
          @mousemove="mouseMovedOn"
          @mouseout="mouseMovedOut"
        >
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy;OpenStreetMap contributors"
          />
          <div v-for="geofeature in this.geofeatures" :key="geofeature.ID">
            <l-circle
              v-if="geofeature.type == 'point'"
              :lat-lng="geofeature.location[0]"
            >
              <l-tooltip>{{ geofeature.geofeature.ID }}</l-tooltip>
              <l-popup>
                Name: {{ geofeature.geofeature.Name }}
                <br />
                Description: {{ geofeature.geofeature.Description }}
                <br />
                ID: {{ geofeature.geofeature.ID }}
                <br />
                Channel: {{ geofeature.geofeature.ChannelID }}
                <br />
                Type: {{ geofeature.geofeature.Geometry.Type }} <br />
                Data:
                <span
                  class="pointer blue"
                  @click="showToolTipJSON = !showToolTipJSON"
                >
                  <span v-if="showToolTipJSON"> hide data </span>
                  <span v-else>show data </span>
                </span>
                <!-- <div class="jsoncontainer">
                  <JSONViewer
                    :json="geofeature.geofeature.Data"
                    :dataFontSize="10"
                    :expanded="true"
                  >
                    <template #name
                      ><span class="f7">GeoFeature Data</span></template
                    >
                  </JSONViewer>
                </div> -->
                <pre class="jsoncontainer">{{
                  geofeature.geofeature.Data
                }}</pre>
              </l-popup>
            </l-circle>
            <l-polyline
              v-if="geofeature.type == 'line'"
              :lat-lngs="geofeature.locations"
              :name="geofeature.geofeature.ID"
            >
              <l-popup>
                Name: {{ geofeature.geofeature.Name }}
                <br />
                Description: {{ geofeature.geofeature.Description }}
                <br />
                ID: {{ geofeature.geofeature.ID }}
                <br />
                Channel: {{ geofeature.geofeature.ChannelID }}
                <br />
                Type: {{ geofeature.geofeature.Geometry.Type }} <br />
                Data:
                <span
                  class="pointer blue"
                  @click="showToolTipJSON = !showToolTipJSON"
                >
                  <span v-if="showToolTipJSON"> hide data </span>
                  <span v-else>show data </span>
                </span>
                <!-- <div class="jsoncontainer">
                  <JSONViewer
                    :json="geofeature.geofeature.Data"
                    :dataFontSize="10"
                    :expanded="true"
                  >
                    <template #name
                      ><span class="f7">GeoFeature Data</span></template
                    >
                  </JSONViewer>
                </div> -->
                <pre class="jsoncontainer">{{
                  geofeature.geofeature.Data
                }}</pre>
              </l-popup>
            </l-polyline>
          </div>
        </l-map>
      </div>
    </div>
    <!-- end parent container -->
  </div>
</template>

<script>
// @ is an alias to /src
// import Helpers from "@/helpers.js";
// import JSONViewer from "@/components/JSONViewer.vue";
import "leaflet/dist/leaflet.css";
import { latLng } from "leaflet";
// import { messageBus } from '@/main';
import {
  LMap,
  LTileLayer,
  LCircle,
  LPopup,
  LTooltip,
  LPolyline,
} from "vue2-leaflet";

export default {
  name: "GeofeatureMap",
  props: {
    geofeatures: Array,
    mapCenter: Object,
  },
  data() {
    return {
      currentCenter: this.mapCenter
        ? latLng(this.mapCenter.LatD, this.mapCenter.LngD)
        : [35.46067, -117.421875], // starting map location
      currentZoom: 13,
      mapOptions: {
        zoomSnap: 0.5,
      },
      cursorLocation: null,
      showToolTipJSON: true,
    };
  },
  computed: {
    cssVars() {
      return {
        "--show-map-json-data": this.showToolTipJSON ? "block" : "none",
      };
    },
  },
  watch: {
    mapCenter() {
      this.centerMapToStartPosition();
    },
  },
  methods: {
    centerMapToStartPosition() {
      this.currentCenter = this.mapCenter
        ? latLng(this.mapCenter.LatD, this.mapCenter.LngD)
        : [35.46067, -117.421875]; // starting map location
    },
    initializeMapValues() {
      this.centerMapToStartPosition();
      this.currentZoom = 13;
    },
    mouseMovedOn(event) {
      this.cursorLocation = event.latlng;
    },
    mouseMovedOut(/* event */) {
      this.cursorLocation = null;
    },
  },
  created: function () {},
  components: {
    // JSONViewer,
    LMap,
    LTileLayer,
    LCircle,
    LTooltip,
    LPopup,
    LPolyline,
  },
};
</script>

<style scoped>
.jsoncontainer {
  display: var(--show-map-json-data);
  max-height: 100px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>